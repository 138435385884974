exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-elements-js": () => import("./../../../src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-foundationdb-js": () => import("./../../../src/pages/foundationdb.js" /* webpackChunkName: "component---src-pages-foundationdb-js" */),
  "component---src-pages-generic-js": () => import("./../../../src/pages/generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mysql-js": () => import("./../../../src/pages/mysql.js" /* webpackChunkName: "component---src-pages-mysql-js" */),
  "component---src-pages-postgres-js": () => import("./../../../src/pages/postgres.js" /* webpackChunkName: "component---src-pages-postgres-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-rocksdb-js": () => import("./../../../src/pages/rocksdb.js" /* webpackChunkName: "component---src-pages-rocksdb-js" */)
}

